import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import styles from './project-breadcrumbs.module.scss';

const ProjectBreadcrumbs = ({ children }) => {
  const title = String(children).toUpperCase();

  return (
    <div className={styles.breadcrumbs}>
      <GatsbyLink
        to="/"
        className={styles.linkProjects}
      >
        PROJECTS
      </GatsbyLink>
      <span className={styles.chevron}>&rsaquo;</span>
      <span className={styles.title}>{ title }</span>
    </div>
  );
};

export default ProjectBreadcrumbs;
