import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames';

import Container from '../../layouts/container';
import ProjectBreadcrumbs from '../../components/project-breadcrumbs';
import HorizontalRule from '../../components/horizontal-rule';
import Testimonial from '../../components/testimonial';

import { formatDateWithMonth } from '../../utils';

import styles from './projects.module.scss';

// NOTA BENE: Do better than this in future...
const PAGE = 3;

const PageProject = ({ data }) => {
  const { title, date, thumbnail } = data.pagesJson.portfolio.projects[PAGE];

  return (
    <Container>
      <ProjectBreadcrumbs>{title}</ProjectBreadcrumbs>
      <p className={styles.date}>{formatDateWithMonth(new Date(date))}</p>
      <h1 className={styles.projectTitle}>{title}</h1>

      <section className={styles.headerWithImage}>
        <div className={styles.headerContent}>
          <p className={classNames(styles.paragraph, styles.summary)}>In August 2019, I was lucky enough to spend a week at General Assembly’s London office getting up to speed with the latest developments in UX design. As part of the course, we were tasked with prototyping an app to solve a problem experienced by other members of the course in their day-to-day lives. I discovered that several of my classmates enjoyed making art in their free time, but expressed frustration that choosing what to draw often ate up all the time available. I decided to try to come up with a solution.</p>
          <Testimonial attribution="comment received during initial user survey">Say I only have half an hour, if I had thirty ideas, I might waste the entire half an hour trying to decide which one I’m going to do</Testimonial>
        </div>
        <Img className={styles.headerImage} fluid={data.headerImage.childImageSharp.fluid} />
      </section>

      <HorizontalRule />

      <section className={styles.projectBody}>
        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Role</h2>
          <p className={styles.paragraph}>Over the course of a five-day sprint, I researched, designed and built a mid-fi prototype for an art inspiration mobile app. I worked alongside a team of 20 other designers, who acted variously as users and colleagues as the need arose. Having this large group of people both as a test base and to bounce ideas off was hugely beneficial to finding a solution in such a short timeframe.</p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Problem</h2>
          <p className={styles.paragraph}>I was talking with a few classmates over a cup of tea and discovered that all of us enjoyed making art in our spare time. Oh, that’s nice, I said, what do you do? Well, I can never quite decide…</p>
          <p className={styles.paragraph}>We’ve all been there. We have some free time and want to fill it with something meaningful — art, exercise, baking — but when it comes to choosing exactly what to do, we’re stuck. And before we know it, the time has disappeared and we’ve achieved nothing.</p>
          <p className={styles.paragraph}>A series of initial interviews with users established two common problems: (i) when they sat down with the intention of drawing, they didn’t know what to draw and (ii) even when they found a source of inspiration it proved too complicated and they ran out of time.</p>
          <div className={classNames(styles.imageContainer, styles.imageContainerMd)}>
            <Img fluid={data.ga_01.childImageSharp.fluid} />
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Design</h2>
          <p className={styles.paragraph}>Initial comparative analysis identified a few competitors, such as Pinterest (which is amazing for inspiration) and Designercize (which offers a great countdown timer), but none offered answers to all of our user problems. My app could fill this gap in the market.</p>
          <p className={styles.paragraph}>So our user, let’s call her Elisa, needs a way to quickly decide what to draw because she wants to make the most of her free time. At a minimum, the app needs to help her decide what to draw and find a project that fits her timeframe.</p>

          <div className={classNames(styles.imageContainer, styles.imageContainerLg)}>
            <Img fluid={data.ga_02.childImageSharp.fluid} />
          </div>

          <p className={styles.paragraph}>I brainstormed a possible user storyboard and then built this out into an initial user flow. This established how the app might work in principle, from opening the app to having a prompt delivered and a timer counting down to a deadline.</p>

          <div className={classNames(styles.imageContainer, styles.imageContainerFull)}>
            <Img fluid={data.ga_03.childImageSharp.fluid} />
          </div>

          <p className={styles.paragraph}>Putting this into practice, I prototyped some very rough initial screens using paper and pen, and then got straight into guerrilla testing them on users.</p>

          <div className={classNames(styles.imageContainer, styles.imageContainerFull)}>
            <Img fluid={data.ga_04.childImageSharp.fluid} />
          </div>

          <p className={styles.paragraph}>Feedback from this first round of user testing was positive, but threw up a couple of problems with the design.</p>
          <p className={styles.paragraph}>First, the ‘drawing in progress’ section didn’t really work. Users wanted the prompt and the timer to be shown throughout this stage. Second, users were worried that with only a single prompt at a time, they might get stuck in an endless loop of flicking through images or word suggestions.</p>
          <p className={styles.paragraph}>Satisfied that the idea worked in principle, I incorporated this feedback into my prototypes and moved to a mid-fi digital version before the next round of testing.</p>

          <div className={styles.adjacentImages}>
            <Img fluid={data.ga_05_01.childImageSharp.fluid} />
            <Img fluid={data.ga_05_02.childImageSharp.fluid} />
          </div>

          <p className={styles.paragraph}>Later that morning, while eating my lunch and playing around with yet more post-its, I realised I had it all wrong — the timeframe option needed to be before the prompts. After all, that’s the only thing Elisa knows when she opens the app.</p>
          <p className={styles.paragraph}>This led me to the final user flow.</p>

          <div className={classNames(styles.imageContainer, styles.imageContainerFull)}>
            <Img fluid={data.ga_06.childImageSharp.fluid} />
          </div>

          <p className={styles.paragraph}>The final design is shown in the prototype below. Click on the image for a chance to try it yourself.</p>
          <div className={classNames(styles.imageContainer, styles.imageContainerSm)}>
            <a href="https://xd.adobe.com/view/d0ad6c20-c159-45a8-7a96-cedc40457cd7-a69c/?fullscreen" target="_blank" rel="noopener noreferrer">
              <Img fluid={data.ga_07.childImageSharp.fluid} />
            </a>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Outcome</h2>
          <p className={styles.paragraph}>At the end of the five-day sprint, I had a functioning mid-fi prototype and a preliminary user flow ready to take into the next round of testing.</p>
          <p className={styles.paragraph}>Users were very satisfied overall with the ease of use and found that they could quickly choose a topic and then complete their drawing within the timeframe allocated.</p>
          <p className={styles.paragraph}>I also raised several possible lines of enquiry for further investigation. For example, one user said: “I want to see the prompt/photo again on the confirmation screen," and another commented: “I like the tone, but there’s too much text on some screens”. The limited timeframe didn’t allow for further investigation, but I would like to follow these points up at a future date.</p>
        </section>
      </section>
    </Container>
  );
};

export const imageQuery = graphql`
  fragment imageQuery on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
{
  pagesJson(portfolio: {projects: {elemMatch: {slug: {regex: "/-/"}}}}) {
    portfolio {
      projects {
        slug
        thumbnail
        title
        date
      }
    }
  }
  headerImage: file(relativePath: { eq: "projects/ga-demo.png" }) {
    ...imageQuery
  }
  ga_01: file(relativePath: { eq: "projects/ga-01.png" }) {
    ...imageQuery
  }
  ga_02: file(relativePath: { eq: "projects/ga-02.png" }) {
    ...imageQuery
  }
  ga_03: file(relativePath: { eq: "projects/ga-03.png" }) {
    ...imageQuery
  }
  ga_04: file(relativePath: { eq: "projects/ga-04.png" }) {
    ...imageQuery
  }
  ga_05_01: file(relativePath: { eq: "projects/ga-05-01.png" }) {
    ...imageQuery
  }
  ga_05_02: file(relativePath: { eq: "projects/ga-05-02.png" }) {
    ...imageQuery
  }
  ga_06: file(relativePath: { eq: "projects/ga-06.png" }) {
    ...imageQuery
  }
  ga_07: file(relativePath: { eq: "projects/ga-07.png" }) {
    ...imageQuery
  }
 
}
`;

export default PageProject;
