import React from 'react';

import styles from './horizontal-rule.module.scss';

// Yes I know there's a <hr> tag.

const HorizontalRule = () => (
  <div className={styles.horizontalRule} />
);

export default HorizontalRule;
