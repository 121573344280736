import React from 'react';

import styles from './testimonial.module.scss';

import { Quotemarks } from '../../assets/svg';

const Testimonial = ({ children, attribution }) => (
  <div className={styles.testimonial}>
    <Quotemarks className={styles.quotemarks} />
    <div className={styles.contentWrapper}>
      <span className={styles.content}>{ children }</span>
      <span className={styles.dash}>&ndash;</span>
      <span className={styles.attribution}>{ attribution }</span>
    </div>
  </div>
);

export default Testimonial;
